@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  font-family: 'Arial', sans-serif;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  
}

.container {
  display: flex;
  justify-content: space-between;
}

.form-section {
  width: 45%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

form {
  display: flex;
  flex-direction: column;
}

form input, form select {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  float: right;
}

form input[type="file"] {
  padding: 5px;
}

.pkpass-preview {
  position: fixed;
  margin-left: 50%;
  width: 315px;
  height: 400px;
  padding: 5px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.pkpass-preview-back.hidden {
  display: none;
}

.pkpass-preview-back.visible {
  display: block;
}
.pkpass-preview-back {
  position: fixed;
  margin-left: 50%;
  width: 315px;
  height: 400px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  background-color: #fff;
}

.input-container {
  display: flex;
  align-items: center;
}

.secondary-section-1 {
float: left; }
  
.secondary-section-2 {
  float: right;
  margin-right: 10px;
  text-align: right;
  }

.auxiliary-section-1 {
  float: left; }
      
.auxiliary-section-2 {
  float: right;
  margin-right: 10px;
  text-align: right;
 }

.container-auxiliary-section {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 15px;
  justify-content:space-between;
}

.container-secondary-section {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-left: 15px;
  justify-content:space-between;
}

.container-secondary-section input {
  width: auto; /* O especifica un ancho, como 200px */
  margin-right: 10px; /* Espacio entre inputs, ajusta según necesites */
}

#colorpicker {
  margin-right: 10px; /* Espacio entre el colorpicker y el input de texto */
}

.thumbnail-image {
  width: 50px;
  height: 50px;
  margin-top: 115px;
  margin-left: 250px;
  position: fixed;
  background-color: #fff;
}

.icon-image {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}


.logo-image {
  width: 50px;
  height: 50px;
  margin-right: 5px; /* Espaciado entre el logo y el título */
}
.logo-section {
  display: flex;
  align-items: center;
}



label {
  display: block;
  margin-bottom: 10px;
  margin-top: 15px;
  color: #999999; /* Usamos tu color corporativo para el texto de las etiquetas */
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
}

.input-group label {
    margin-right: 10px;
}

.primary-section {
  margin-top: 40px;
  margin-left: 15px;

}
.label-text {
  font-size: 12px;
  font-weight: 700;
  }

 .value-text {
  font-size: 14px;
  padding-bottom: 20px;
  }

  .label-text-back {
    margin-left: 10px;
    line-height: 0.5;
    font-size: 12px;
    font-weight: 700;
    color: #3d3d3d;
    }
  
   .value-text-back{
    margin-left: 10px;
    line-height: 0.5;
    font-size: 12px;
    color: #3d3d3d;
    }

.primary-field {
  font-size: 30px;
  
}
.auxiliary-field {
  font-size: 13px;
  
  }

.auxiliary-section {
  margin-top: -5px;
  margin-left: 15px;
  }

  hr.back {
    border-top: 1px solid lightgrey;
  }

  .btn-icon-turn-card {
    background: none;
    border: none; /* Color del ícono */
    font-size: 24px;  /* Tamaño del ícono */
    cursor: pointer;
    transition: color 0.3s ease;
    position: absolute; /* Posicionamiento absoluto en relación al elemento padre con posición relativa */
    bottom: 0px; /* Separación desde el borde inferior de la tarjeta */
    right: 5px; /* Separación desde el borde derecho de la tarjeta */
  
    
}

.btn-icon-turn-card:hover {
    color: #007BFF;  /* Cambia el color al pasar el mouse por encima */
}

/* Estilos Base para el Botón */
.form-button {
  font-family: 'Arial', sans-serif; 
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 14px;
}

/* Botón Principal (color corporativo) */
.form-button.primary {
  background-color: #ef4b26;
  color: white;
}

/* Botón activacion */
.project-button-activate {
  margin-right: 20px;
  float: right;
  background: #199C09;
  color: white;
  padding: 10px 20px;
  border: solid;
  border-color: #dbf6d7;
  border-width: 3px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight:500;
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.form-button.primary:hover {
  background-color: #d43f15; /* Un tono más oscuro del color corporativo para el hover */
  transform: scale(1.05);    /* Hace el botón un poco más grande al pasar el cursor */
}

/* Botón Secundario (gris claro) */
.form-button.secondary {
  background-color: #f5f5f5; /* Gris claro */
  color: #333; /* Color de texto oscuro para contraste */
}

.form-button.secondary:hover {
  background-color: #e5e5e5; /* Un tono más oscuro del gris claro para el hover */
  transform: scale(1.05);
}

/* Si necesitas un botón con borde pero transparente (útil en algunos diseños) */
.form-button.outline {
  background-color: transparent;
  border: 2px solid #ef4b26; /* Color corporativo para el borde */
  color: #ef4b26; /* Color corporativo para el texto */
}

.form-button.outline:hover {
  background-color: #ef4b26; /* Color corporativo de fondo al hacer hover */
  color: white; /* Texto en blanco al hacer hover */
}

.scroll-container {
  border-top-color: #007BFF;
  width: 100%;  /* Ancho del contenedor */
  height: 100vh;  /* Altura del contenedor */
  overflow: auto;  /* Mostrar barras de desplazamiento si es necesario */
  /* Puedes utilizar overflow: scroll; si siempre quieres mostrar las barras */
}


.project-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 400; /* Peso correspondiente a Roboto Light */
  font-size: 20px;
  margin-right: 20px;

}

.project-button {
  background-color: transparent;
  border: solid;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  cursor: pointer;
  color:  gray; /* Este es uno de tus colores corporativos */
  padding: 4px 6px 2px 6px ;
  margin-top: 3px;
  margin-right: 15px;
  width: 20px;
  height: 25px;
  text-align: center;
}

.project-button:hover {
  color: #242424; /* O cualquier otro color al pasar el mouse */
}

.header-project {
padding: 5px;
border-bottom-width: 1px;
border-bottom-style: solid;
border-bottom-color: lightgrey;
}